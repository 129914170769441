<template>
    <div class="tourist">
        <div class="tabs">
            <!--            <div :class="{ active: actviteId == '' }" @click="goType('')">全部景区</div>-->
            <div v-for="item in mapList" :class="{ active: actviteId == item.id }" @click="goType(item.id)"
                 :key="item.id">
                {{ item.name }}
            </div>
        </div>

        <div class="content">
            <div class="left">
                <!-- 每日游客总量 -->
                <div class="left_top">
                    <img src="../assets/tourist/3-title-visitor@2x.png" alt=""/>
                    <div class="all-select-box flex ac">

                        <div class="left_date all-date-box">
                            <el-date-picker
                                    v-model="date1"
                                    type="daterange"
                                    range-separator="-"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="pickerOptions"
                                    value-format="yyyy-MM-dd"
                                    placement="bottom-start"
                                    size="small"
                                    style="width: 100%"
                                    @change="handleDate1"
                            ></el-date-picker>
                        </div>

                        <el-popover
                                placement="bottom"
                                width="480"
                                v-model="kjvisible1">
                            <div>
                                <div class="chooseYearBox flex ac">
                                    <div style="margin-right: 5px">选择年份:</div>
                                    <el-date-picker
                                            v-model="yearValue1"
                                            type="year"
                                            placeholder="选择年"
                                            @change="chooseYaer1"
                                            format="yyyy"
                                            value-format="yyyy"
                                    >
                                    </el-date-picker>
                                </div>
                                <div class="month-box flex fw">
                                    <div style="margin-right: 5px">选择月份:</div>
                                    <div style="width: 80%">
                                        <el-checkbox-group v-model="monthValue1" size="mini" :disabled="checkDisable"
                                                           :max="1"
                                                           @change="handleMonth">
                                            <el-checkbox label="01" border>一月</el-checkbox>
                                            <el-checkbox label="02" border>二月</el-checkbox>
                                            <el-checkbox label="03" border>三月</el-checkbox>
                                            <el-checkbox label="04" border>四月</el-checkbox>
                                            <el-checkbox label="05" border>五月</el-checkbox>
                                            <el-checkbox label="06" border>六月</el-checkbox>
                                            <el-checkbox label="07" border>七月</el-checkbox>
                                            <el-checkbox label="08" border>八月</el-checkbox>
                                            <el-checkbox label="09" border>九月</el-checkbox>
                                            <el-checkbox label="10" border>十月</el-checkbox>
                                            <el-checkbox label="11" border>十一月</el-checkbox>
                                            <el-checkbox label="12" border>十二月</el-checkbox>
                                        </el-checkbox-group>
                                    </div>

                                </div>
                                <div class="month-box flex fw">
                                    <div style="margin-right: 5px">选择节假日:</div>
                                    <div style="width: 80%">
                                        <el-checkbox-group v-model="holidayValue1" size="mini" :disabled="checkDisable2"
                                                           :max="1"
                                                           @change="handleHoliday">
                                            <div class="flex ac fw">
                                                <div v-for="item in holidayList" :key="item.id"
                                                     style="margin-right: 5px">
                                                    <el-checkbox :label="item.name" border/>
                                                </div>
                                            </div>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="cannelKj(1)">取消</el-button>
                                <el-button type="primary" size="mini" @click="sumbitKj(1)">确定</el-button>
                            </div>
                            <div class="kj-box" slot="reference">
                                <p v-if="monthValue1.length>0">{{yearValue1}} {{monthValue1.toString()}}</p>
                                <p v-else-if="holidayValue1.length>0">{{yearValue1}} {{holidayValue1.toString()}}</p>
                                <p v-else>快捷筛选</p>
                            </div>
                        </el-popover>
                    </div>

                </div>
                <div class="allnum_echart">
                    <dv-loading v-if="loading1" style="height: 320px">Loading...</dv-loading>
                    <Echart v-else :options="options1" id="chart1" height="410px" width="1200px"></Echart>
                </div>
                <div class="left_bottom">
                    <!-- 入园时间 -->
                    <div class="left_bottom_box">
                        <div class="left_bottom_top">
                            <img src="../assets/tourist/3-title-entertime@2x.png" alt=""/>
                            <div class="date_box all-date-box">
                                <el-date-picker
                                        v-model="date2"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        placeholder="选择日期"
                                        size="small"
                                        style="width: 100%"
                                        placement="bottom-start"
                                        @change="handleDate2"
                                ></el-date-picker>
                            </div>
                        </div>
                        <div class="time_echart">
                            <dv-loading v-if="loading2" style="height: 120px">Loading...</dv-loading>
                            <Echart v-else :options="options2" id="chart2" height="185px" width="580px"></Echart>
                        </div>
                    </div>
                    <!-- 游客类型 -->
                    <div class="left_bottom_box">
                        <div class="left_bottom_top">
                            <img src="../assets/tourist/3-title-visitortype@2x.png" alt=""/>
                            <div class="date_box2 all-date-box">
                                <el-date-picker
                                        v-model="date4"
                                        type="daterange"
                                        range-separator="-"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        placeholder="选择日期"
                                        :picker-options="pickerOptions"
                                        value-format="yyyy-MM-dd"
                                        size="small"
                                        style="width: 100%"
                                        placement="bottom-start"
                                        @change="handleDate4"
                                ></el-date-picker>
                            </div>
                            <el-popover
                                    placement="bottom"
                                    width="480"
                                    v-model="kjvisible2">
                                <div>
                                    <div class="chooseYearBox flex ac">
                                        <div style="margin-right: 5px">选择年份:</div>
                                        <el-date-picker
                                                v-model="yearValue2"
                                                type="year"
                                                placeholder="选择年"
                                                @change="chooseYaer2"
                                                format="yyyy"
                                                value-format="yyyy"
                                        >
                                        </el-date-picker>
                                    </div>
                                    <div class="month-box flex fw">
                                        <div style="margin-right: 5px">选择月份:</div>
                                        <div style="width: 80%">
                                            <el-checkbox-group v-model="monthValue2" size="mini"
                                                               :disabled="checkDisable"
                                                               :max="1"
                                                               @change="handleMonth2">
                                                <el-checkbox label="01" border>一月</el-checkbox>
                                                <el-checkbox label="02" border>二月</el-checkbox>
                                                <el-checkbox label="03" border>三月</el-checkbox>
                                                <el-checkbox label="04" border>四月</el-checkbox>
                                                <el-checkbox label="05" border>五月</el-checkbox>
                                                <el-checkbox label="06" border>六月</el-checkbox>
                                                <el-checkbox label="07" border>七月</el-checkbox>
                                                <el-checkbox label="08" border>八月</el-checkbox>
                                                <el-checkbox label="09" border>九月</el-checkbox>
                                                <el-checkbox label="10" border>十月</el-checkbox>
                                                <el-checkbox label="11" border>十一月</el-checkbox>
                                                <el-checkbox label="12" border>十二月</el-checkbox>
                                            </el-checkbox-group>
                                        </div>

                                    </div>
                                    <div class="month-box flex fw">
                                        <div style="margin-right: 5px">选择节假日:</div>
                                        <div style="width: 80%">
                                            <el-checkbox-group v-model="holidayValue2" size="mini"
                                                               :disabled="checkDisable2"
                                                               :max="1"
                                                               @change="handleHoliday2">
                                                <div class="flex ac fw">
                                                    <div v-for="item in holidayList" :key="item.id"
                                                         style="margin-right: 5px">
                                                        <el-checkbox :label="item.name" border/>
                                                    </div>
                                                </div>
                                            </el-checkbox-group>
                                        </div>
                                    </div>
                                </div>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="cannelKj(2)">取消</el-button>
                                    <el-button type="primary" size="mini" @click="sumbitKj(2)">确定</el-button>
                                </div>
                                <div class="kj-box" slot="reference">
                                    <p v-if="monthValue2.length>0">{{yearValue2}} {{monthValue2.toString()}}</p>
                                    <p v-else-if="holidayValue2.length>0">{{yearValue2}}
                                        {{holidayValue2.toString()}}</p>
                                    <p v-else>快捷筛选</p>
                                </div>
                            </el-popover>
                        </div>
                        <dv-loading v-if="loading4" style="height: 120px">Loading...</dv-loading>
                        <div v-if="!loading4" class="type_bootom">
                            <div class="type_bootom_box type_bootom_box2">
                                <!--                                <div class="type_bootom_tit">类别分布</div>-->
                                <div class="type_line" v-for="(item5, index5) in type_list" :key="index5">
                                    <div class="type_line1">{{ item5.title }}</div>
                                    <div class="type_line_img">
                                        <div class="line_img_box" :style="`width: ${item5.percent}%;`">
                                            <img src="../assets/tourist/3-title-visitortype-icon8@2x.png" alt=""
                                                 class="line_img"/>
                                            <img src="../assets/tourist/3-title-visitortype-icon9@2x.png" alt=""
                                                 class="line_point"/>
                                        </div>
                                    </div>
                                    <div class="type_line3" style="margin-right: 14px">{{ item5.value }} <span
                                            style="opacity: 0.7;color: #ffffff;font-size: 14px;">人</span></div>
                                    <div class="type_line3">{{ item5.percent }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <!-- 入园方式 -->
                <div class="right_top">
                    <img src="../assets/tourist/3-title-Entry_mode@2x.png" alt=""/>
                    <div class="date_box all-date-box">
                        <el-date-picker
                                v-model="date3"
                                type="daterange"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                size="small"
                                style="width: 100%"
                                placement="bottom-start"
                                @change="handleDate3"
                        ></el-date-picker>
                    </div>
                    <el-popover
                            placement="bottom"
                            width="480"
                            v-model="kjvisible3">
                        <div>
                            <div class="chooseYearBox flex ac">
                                <div style="margin-right: 5px">选择年份:</div>
                                <el-date-picker
                                        v-model="yearValue3"
                                        type="year"
                                        placeholder="选择年"
                                        @change="chooseYaer3"
                                        format="yyyy"
                                        value-format="yyyy"
                                >
                                </el-date-picker>
                            </div>
                            <div class="month-box flex fw">
                                <div style="margin-right: 5px">选择月份:</div>
                                <div style="width: 80%">
                                    <el-checkbox-group v-model="monthValue3" size="mini" :disabled="checkDisable"
                                                       :max="1"
                                                       @change="handleMonth3">
                                        <el-checkbox label="01" border>一月</el-checkbox>
                                        <el-checkbox label="02" border>二月</el-checkbox>
                                        <el-checkbox label="03" border>三月</el-checkbox>
                                        <el-checkbox label="04" border>四月</el-checkbox>
                                        <el-checkbox label="05" border>五月</el-checkbox>
                                        <el-checkbox label="06" border>六月</el-checkbox>
                                        <el-checkbox label="07" border>七月</el-checkbox>
                                        <el-checkbox label="08" border>八月</el-checkbox>
                                        <el-checkbox label="09" border>九月</el-checkbox>
                                        <el-checkbox label="10" border>十月</el-checkbox>
                                        <el-checkbox label="11" border>十一月</el-checkbox>
                                        <el-checkbox label="12" border>十二月</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                            <div class="month-box flex fw">
                                <div style="margin-right: 5px">选择节假日:</div>
                                <div style="width: 80%">
                                    <el-checkbox-group v-model="holidayValue3" size="mini" :disabled="checkDisable2"
                                                       :max="1"
                                                       @change="handleHoliday3">
                                        <div class="flex ac fw">
                                            <div v-for="item in holidayList" :key="item.id"
                                                 style="margin-right: 5px">
                                                <el-checkbox :label="item.name" border/>
                                            </div>
                                        </div>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="cannelKj(3)">取消</el-button>
                            <el-button type="primary" size="mini" @click="sumbitKj(3)">确定</el-button>
                        </div>
                        <div class="kj-box" slot="reference">
                            <p v-if="monthValue3.length>0">{{yearValue3}} {{monthValue3.toString()}}</p>
                            <p v-else-if="holidayValue3.length>0">{{yearValue3}} {{holidayValue3.toString()}}</p>
                            <p v-else>快捷筛选</p>
                        </div>
                    </el-popover>

                </div>
                <dv-loading v-if="loading3" style="height: 137px">Loading...</dv-loading>
                <div v-else class="right_echart_box">
                    <div class="echart_l">
                        <Echart :options="options3" id="chart3" height="224px" width="224px"></Echart>
                    </div>
                    <div class="echart_r">
                        <div class="echart_r_box" v-for="(item3, index3) in xData3" :key="index3">
                            <div class="echart_r_box1" :style="show3(index3, '4px')">{{ item3.name }}</div>
                            <div class="echart_r_box2" :style="show3(index3, '1px')">{{ item3.value }}</div>
                        </div>
                    </div>
                </div>
                <div class="flex ac jb all-ly-box" >
                    <img src="../assets/tourist/3-title-from.png" alt="" class="right_tit"/>
                    <div class="date_box all-date-box">
                        <el-date-picker
                                v-model="date5"
                                type="daterange"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                size="small"
                                style="width: 100%"
                                placement="bottom-start"
                                @change="handleDate5"
                        ></el-date-picker>
                    </div>
                </div>
                <!-- 游客来源 -->
                <div class="origin_top">
                    <img src="../assets/tourist/3-title-from-icon2@2x.png" alt=""/>
                    <span>游客总数(人次)：</span>
                    <span class="num">{{total_num}}</span>
                    <!--                  <span class="num2">万</span>-->
                </div>
                <!--                <div class="ly-title flex ac jc">-->
                <!--                    <img src="../assets/tourist/3-title-from-icon2@2x.png" alt="">-->
                <!--                    <div>游客总数(人次):{{total_num}}万</div>-->
                <!--                </div>-->
                <div class="origin">
                    <div class="origin_box" v-for="(item8, index8) in sourceList" :key="index8">
                        <div class="origin_box_l">
                            {{ item8.province }}
                        </div>
                        <div class="origin_box_r">
                            <span class="origin_box_r1">{{ item8.num }}</span>
                            <span>人</span>
                        </div>
                    </div>
                    <img v-if="sourceList.length < 1" src="../assets/img/1-title-ticket-empty@2x.png" alt=""
                         class="empty"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getMap,
        touristList,
        touristTimeList,
        touristTypeData,
        touristTypeList,
        touristSourceList,
        tjHolidayList
    } from "../api";
    import {formatTime} from "../utils/index.js";
    import Echart from "@/common/echart";
    import * as echarts from "echarts";

    export default {
        components: {Echart},
        props: {},
        data() {
            return {
                actviteId: "",
                mapList: [],
                // 游客总量
                loading1: false,
                date1: [],
                options1: {},
                xData1: [],
                yData1: [],
                isRestart: false,
                pickerMinDate: '',
                pickerOptions: { // 限制日期范围为一个月
                    onPick: ({maxDate, minDate}) => {
                        this.isRestart = false;
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = "";
                        }
                    },

                    disabledDate: (time) => {
                        if (this.pickerMinDate !== "") {
                            const one = 3 * 31 * 24 * 3600 * 1000;
                            const minTime = this.pickerMinDate - one;
                            const maxTime = this.pickerMinDate + one;
                            return time.getTime() < minTime || time.getTime() > maxTime;
                        }
                    },
                },
                // 入园时间
                loading2: false,
                date2: formatTime(new Date(), "yyyy-MM-dd"),
                options2: {},
                xData2: [],
                yData2: [],
                // 入园方式
                loading3: false,
                date3: [],
                date5: [],
                options3: {},
                xData3: [],
                allNum: "",
                // 游客类型
                loading4: false,
                date4: [],
                age_list: [],
                sex_list: [],
                type_list: [],
                //游客来源
                sourceList: [],
                total_num: "",

                // 全部的折线图
                nineXData: [],
                nineXTitle: [],
                nineYData1: [],
                nineYData2: [],
                nineYData3: [],
                nineYData4: [],
                nineYData5: [],
                nineYData6: [],
                nineYData7: [],
                nineYData8: [],
                nineYData9: [],

                // 日期快捷选择
                kjvisible1: false,
                kjvisible2: false,
                kjvisible3: false,

                yearValue1: new Date().getFullYear().toString(),
                yearValue2: new Date().getFullYear().toString(),
                yearValue3: new Date().getFullYear().toString(),

                monthValue1: [],
                monthValue2: [],
                monthValue3: [],

                holidayList: [],

                holidayValue1: [],
                holidayValue2: [],
                holidayValue3: [],

                checkDisable: false,
                checkDisable2: false
            };
        },
        computed: {},
        created() {
            let end = formatTime(new Date(), "yyyy-MM-dd");
            let start = formatTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30), "yyyy-MM-dd");
            this.date1 = [start, end];
            this.date3 = [start, end];
            this.date5 = [start, end];
            this.date4 = [start, end];
            this.actviteId = this.$route.query.actviteId || "";
            this.goType(this.actviteId);
            this.getMap();
            this.getTjHolidayList(new Date().getFullYear());
        },
        mounted() {
        },
        watch: {},
        methods: {
            // 景区
            getMap() {
                // getMap().then((res) => {
                //     this.mapList = res.data;
                // });
                touristList().then((res) => {
                    res.data.forEach((item) => {
                        const obj = {}
                        obj.name = item.title
                        obj.id = item.id
                        this.mapList.push(obj)
                    });
                })
            },
            // 获取节假日列表
            getTjHolidayList(year) {
                tjHolidayList({year: year}).then(res => {
                    this.holidayList = res.data
                })
            },
            // tab切换
            goType(val) {
                this.actviteId = val;
                this.$router
                    .push({
                        path: this.$route.path,
                        query: {...this.$route.query, actviteId: val},
                    })
                    .catch(() => {
                    });

                this.touristList();
                this.touristTimeList();
                this.touristTypeData();
                this.touristTypeList();
                this.touristSourceList();
            },
            // 游客总量-日期
            handleDate1(date) {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.touristList();
            },
            // 入园时间-日期
            handleDate2(date) {
                this.touristTimeList();
            },
            // 入园方式-日期
            handleDate3(date) {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.touristTypeData();
            },
            // 入园方式-日期
            handleDate5(date) {
                this.touristSourceList()
            },
            // 游客类型-日期
            handleDate4(date) {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.touristTypeList();
            },
            // 游客总量
            touristList() {
                let params = {
                    scenic_spot_id: this.actviteId,
                    start_date: this.date1[0] || "",
                    end_date: this.date1[1] || "",
                };
                this.xData1 = [];
                this.yData1 = [];
                this.loading1 = true;
                console.log('this.actviteId', this.actviteId)
                touristList(params).then((res) => {
                    if (this.actviteId != 0) {
                        let data = res.data;
                        data.forEach((item) => {
                            this.xData1.push(item.date);
                            this.yData1.push(item.num);
                        });
                        this.loading1 = false;
                        this.getOptions1();
                    } else {
                        console.log('res', res)
                        this.resetZxt()
                        let data = res.data;
                        data.forEach((item) => {
                            this.nineXTitle.push(item.title);
                        });
                        // 本来是9条的 == 9
                        if (data.length == 6) {
                            data[0].data.forEach((item) => {
                                this.nineXData.push(item.date);
                                this.nineYData1.push(item.num);
                            });
                            data[1].data.forEach((item) => {
                                this.nineYData2.push(item.num);
                            });
                            data[2].data.forEach((item) => {
                                this.nineYData3.push(item.num);
                            });
                            data[3].data.forEach((item) => {
                                this.nineYData4.push(item.num);
                            });
                            data[4].data.forEach((item) => {
                                this.nineYData5.push(item.num);
                            });
                            data[5].data.forEach((item) => {
                                this.nineYData6.push(item.num);
                            });
                            // data[6].data.forEach((item) => {
                            //     this.nineYData7.push(item.num);
                            // });
                            // data[7].data.forEach((item) => {
                            //     this.nineYData8.push(item.num);
                            // });
                            // data[8].data.forEach((item) => {
                            //     this.nineYData9.push(item.num);
                            // });
                        }
                        this.loading1 = false;
                        this.getOptions11();
                    }
                });
            },
            // 入园时间
            touristTimeList() {
                let params = {
                    scenic_spot_id: this.actviteId,
                    date: this.date2,
                };
                this.xData2 = [];
                this.yData2 = [];
                this.loading2 = true;
                touristTimeList(params).then((res) => {
                    let data = res.data;
                    data.forEach((item) => {
                        this.xData2.push(item.time);
                        this.yData2.push(item.num);
                    });
                    this.loading2 = false;
                    this.getOptions2();
                });
            },
            // 入园方式
            touristTypeData() {
                let params = {
                    scenic_spot_id: this.actviteId,
                    start_date: this.date3[0] || "",
                    end_date: this.date3[1] || "",
                };
                // let params = {
                //     scenic_spot_id: this.actviteId,
                //     month: this.date3,
                // };
                this.loading3 = true;
                this.xData3 = [];
                touristTypeData(params).then((res) => {
                    let data = res.data.type_list;
                    this.allNum = res.data.total_num;
                    data.forEach((item) => {
                        this.xData3.push({name: item.type_name, value: item.num});
                    });
                    this.loading3 = false;
                    this.getOptions3();
                });
            },
            // 游客类型
            touristTypeList() {
                let params = {
                    scenic_spot_id: this.actviteId,
                    start_date: this.date4[0] || "",
                    end_date: this.date4[1] || "",
                };
                // let params = {
                //     scenic_spot_id: this.actviteId,
                //     month: this.date4,
                // };
                this.age_list = [];
                this.sex_list = [];
                this.type_list = [];
                this.loading4 = true;
                touristTypeList(params).then((res) => {
                    this.age_list = res.data.age_list;
                    this.sex_list = res.data.sex_list;
                    this.type_list = res.data.type_list;
                    this.loading4 = false;
                });
            },
            //
            show4(index) {
                let angle = index * 20;
                return `transform: rotate(${angle}deg)`;
            },
            // 游客来源
            touristSourceList() {
                let params = {
                    scenic_spot_id: this.actviteId,
                    start_date: this.date5[0] || "",
                    end_date: this.date5[1] || "",
                };
                touristSourceList(params).then((res) => {
                    this.sourceList = res.data.data;
                    this.total_num = res.data.total_num;
                });
            },

            chooseYaer1(val) {
                this.monthValue1 = []
                this.holidayValue1 = []
                this.checkDisable = false
                this.checkDisable2 = false
                this.getTjHolidayList(val)
                if (this.monthValue1.length > 0) {
                    var lasyDay = ''
                    lasyDay = this.getLastDay(val, this.monthValue1.toString())
                    console.log('lasyDay', lasyDay)
                    this.date1 = [val + '-' + this.monthValue1.toString() + '-01', val + '-' + this.monthValue1.toString() + '-' + lasyDay];
                }
            },

            handleMonth(val) {
                if (val.length > 0) {
                    this.checkDisable2 = true
                    var lasyDay = ''
                    lasyDay = this.getLastDay(this.yearValue1, val.toString())
                    console.log('lasyDay', lasyDay)
                    this.date1 = [this.yearValue1 + '-' + val.toString() + '-01', this.yearValue1 + '-' + val.toString() + '-' + lasyDay];
                } else {
                    this.checkDisable2 = false
                }
            },
            handleHoliday(val) {
                if (val.length > 0) {
                    this.checkDisable = true
                    this.holidayList.forEach(item => {
                        if (item.name == val.toString()) {
                            this.date1 = [item.start_date, item.end_date];
                        }
                    })
                } else {
                    this.checkDisable = false
                }
            },


            chooseYaer2(val) {
                this.monthValue2 = []
                this.holidayValue2 = []
                this.checkDisable = false
                this.checkDisable2 = false
                this.getTjHolidayList(val)
                if (this.monthValue2.length > 0) {
                    var lasyDay = ''
                    lasyDay = this.getLastDay(val, this.monthValue2.toString())
                    console.log('lasyDay', lasyDay)
                    this.date4 = [val + '-' + this.monthValue2.toString() + '-01', val + '-' + this.monthValue2.toString() + '-' + lasyDay];
                }
            },

            handleMonth2(val) {
                if (val.length > 0) {
                    this.checkDisable2 = true
                    var lasyDay = ''
                    lasyDay = this.getLastDay(this.yearValue2, val.toString())
                    console.log('lasyDay', lasyDay)
                    this.date4 = [this.yearValue2 + '-' + val.toString() + '-01', this.yearValue2 + '-' + val.toString() + '-' + lasyDay];
                } else {
                    this.checkDisable2 = false
                }
            },
            handleHoliday2(val) {
                if (val.length > 0) {
                    this.checkDisable = true
                    this.holidayList.forEach(item => {
                        if (item.name == val.toString()) {
                            this.date4 = [item.start_date, item.end_date];
                        }
                    })
                } else {
                    this.checkDisable = false
                }
            },


            chooseYaer3(val) {
                this.monthValue3 = []
                this.holidayValue3 = []
                this.checkDisable = false
                this.checkDisable2 = false
                this.getTjHolidayList(val)
                if (this.monthValue3.length > 0) {
                    var lasyDay = ''
                    lasyDay = this.getLastDay(val, this.monthValue3.toString())
                    console.log('lasyDay', lasyDay)
                    this.date3 = [val + '-' + this.monthValue3.toString() + '-01', val + '-' + this.monthValue3.toString() + '-' + lasyDay];
                }
            },

            handleMonth3(val) {
                if (val.length > 0) {
                    this.checkDisable2 = true
                    var lasyDay = ''
                    lasyDay = this.getLastDay(this.yearValue3, val.toString())
                    console.log('lasyDay', lasyDay)
                    this.date3 = [this.yearValue3 + '-' + val.toString() + '-01', this.yearValue3 + '-' + val.toString() + '-' + lasyDay];
                } else {
                    this.checkDisable2 = false
                }
            },
            handleHoliday3(val) {
                if (val.length > 0) {
                    this.checkDisable = true
                    this.holidayList.forEach(item => {
                        if (item.name == val.toString()) {
                            this.date3 = [item.start_date, item.end_date];
                        }
                    })
                } else {
                    this.checkDisable = false
                }
            },

            //某年某月最后一天
            getLastDay(year, month) {
                var nyear = year;
                var nmonth = month++;
                if (month > 12) {
                    nmonth -= 12;
                    nyear++;
                }
                var n_date = new Date(nyear, nmonth, 1);
                return (new Date(n_date.getTime() - 1000 * 60 * 60 * 24)).getDate();
            },


            cannelKj(val) {
                if (val == 1) {
                    this.kjvisible1 = false
                    // this.yearValue1 =''
                    // this.monthValue1 =[]
                    // this.holidayValue1 =[]
                }
                if (val == 2) {
                    this.kjvisible2 = false
                }
                if (val == 3) {
                    this.kjvisible3 = false
                }
            },
            sumbitKj(val) {
                if (val == 1) {
                    if (!this.yearValue1) {
                        this.$message.error('请选择年份')
                        return false
                    }
                    if (this.monthValue1.length == 0 && this.holidayValue1.length == 0) {
                        this.$message.error('请选择月份或者节假日')
                        return false
                    }
                    this.kjvisible1 = false
                    this.touristList();
                }
                if (val == 2) {
                    if (!this.yearValue2) {
                        this.$message.error('请选择年份')
                        return false
                    }
                    if (this.monthValue2.length == 0 && this.holidayValue2.length == 0) {
                        this.$message.error('请选择月份或者节假日')
                        return false
                    }
                    this.kjvisible2 = false
                    this.touristTypeList();
                }
                if (val == 3) {
                    if (!this.yearValue3) {
                        this.$message.error('请选择年份')
                        return false
                    }
                    if (this.monthValue3.length == 0 && this.holidayValue3.length == 0) {
                        this.$message.error('请选择月份或者节假日')
                        return false
                    }
                    this.kjvisible3 = false
                    this.touristTypeData();
                }

            },
            // 游客总量-图表
            getOptions1() {
                this.options1 = {
                    //hover气泡
                    tooltip: {
                        trigger: "axis",
                        position: "right",
                        formatter: "{b}<br/>游客数 : {c}人",
                        axisPointer: {
                            lineStyle: {
                                color: "#18CFD2",
                            },
                        },
                    },
                    grid: {
                        top: "4%",
                        left: "1%",
                        right: "3%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: this.xData1,
                    },
                    yAxis: {
                        type: "value",
                        // 网格线
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed", //y轴分割线类型
                                color: "rgba(102,102,102,0.3)",
                            },
                        },
                    },
                    series: [
                        {
                            data: this.yData1,
                            showSymbol: false, //去掉折线上的小圆点
                            smooth: true,
                            type: "line",
                            //设置折线颜色和粗细
                            lineStyle: {
                                width: 2,
                                color: "#44E2F0",
                            },
                            //设置面积区域为渐变效果
                            areaStyle: {
                                opacity: 0.5,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "#00C8D9",
                                    },
                                    {
                                        offset: 1,
                                        color: "#063666",
                                    },
                                ]),
                            },
                        },
                    ],
                };
            },
            // 九条线
            getOptions11() {
                const colorList = ["#EE0D0D", '#00ABFF', '#FFC003', '#4FF6FF', '#E18BFF', '#B7FF63', '#FF7468', '#FD9A17', '#FD9A17']
                this.options1 = {
                    legend: {
                        icon: 'circle',
                        top: '0%',
                        left: '5%',
                        itemWidth: 12,
                        itemGap: 40,
                        textStyle: {
                            color: '#FFFFFF'
                        },
                    },
                    //hover气泡
                    tooltip: {
                        trigger: "axis",
                        position: "right",
                        // formatter: "{b}<br/>游客数 : {c}人",
                        axisPointer: {
                            lineStyle: {
                                color: "#18CFD2",
                            },
                        },
                    },
                    grid: {
                        top: "12%",
                        left: "1%",
                        right: "3%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: this.nineXData,
                    },
                    yAxis: {
                        type: "value",
                        // 网格线
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed", //y轴分割线类型
                                color: "rgba(102,102,102,0.3)",
                            },
                        },
                    },
                    series: [
                        {
                            name: this.nineXTitle[0],
                            type: 'line',
                            data: this.nineYData1,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[0]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[0]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[0],
                                    borderColor: colorList[0]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[1],
                            type: 'line',
                            data: this.nineYData2,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[1]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[1]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[1],
                                    borderColor: colorList[1]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[2],
                            type: 'line',
                            data: this.nineYData3,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[2]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[2]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[2],
                                    borderColor: colorList[2]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[3],
                            type: 'line',
                            data: this.nineYData4,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[3]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[3]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[3],
                                    borderColor: colorList[3]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[4],
                            type: 'line',
                            data: this.nineYData5,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[4]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[4]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[4],
                                    borderColor: colorList[4]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[5],
                            type: 'line',
                            data: this.nineYData6,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[5]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[5]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[5],
                                    borderColor: colorList[5]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[6],
                            type: 'line',
                            data: this.nineYData7,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[6]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[6]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[6],
                                    borderColor: colorList[6]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[7],
                            type: 'line',
                            data: this.nineYData8,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[7]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[7]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[7],
                                    borderColor: colorList[7]
                                }
                            }
                        },
                        {
                            name: this.nineXTitle[8],
                            type: 'line',
                            data: this.nineYData9,
                            lineStyle: {
                                width: 2,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: colorList[8]
                                },
                                    {
                                        offset: 1,
                                        color: colorList[8]
                                    }
                                ]),
                            },
                            itemStyle: {
                                normal: {
                                    color: colorList[8],
                                    borderColor: colorList[8]
                                }
                            }
                        },
                    ],
                };
            },
            // 入园时间-图表
            getOptions2() {
                this.options2 = {
                    //hover气泡
                    tooltip: {
                        trigger: "axis",
                        position: "right",
                        formatter: "{b}<br/>入园人数 : {c}人",
                        axisPointer: {
                            lineStyle: {
                                color: "#18CFD2",
                            },
                        },
                    },
                    grid: {
                        top: "4%",
                        left: "3%",
                        right: "3%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        data: this.xData2,
                    },
                    yAxis: {
                        type: "value",
                        // 网格线
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed", //y轴分割线类型
                                color: "rgba(102,102,102,0.3)",
                            },
                        },
                    },
                    series: [
                        {
                            data: this.yData2,
                            type: "bar",
                            barMaxWidth: 10,
                            showBackground: true,
                            backgroundStyle: {
                                color: "rgba(180, 180, 180, 0.1)",
                            },
                            //鼠标悬停时
                            emphasis: {
                                itemStyle: {
                                    // 设置柱状渐变色
                                    color: "#FB8341",
                                },
                            },
                            itemStyle: {
                                // 设置柱状渐变色
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                                    {
                                        offset: 0,
                                        color: "#2fc0d2",
                                    },
                                    {
                                        offset: 1,
                                        color: "#3861fb",
                                    },
                                ]),
                            },
                        },
                    ],
                };
            },
            // 入园方式-图表
            getOptions3() {
                this.options3 = {
                    //hover气泡
                    tooltip: {
                        trigger: "item",
                        position: "right",
                        formatter: "{b}<br/>{a} : {c}人 {d}%",
                    },
                    //修改图表颜色
                    color: ["#fb7e48", "#FFBB00", "#37ECFF", "#3DC95F", "#9231FF", "#036CF3"],

                    // legend: {
                    //   top: "5%",
                    //   left: "center",
                    // },

                    //graphic控制环形中间出现字，其中有两个对象，就是两行字
                    graphic: [
                        {
                            type: "text", //控制内容为文本文字
                            left: "center",
                            top: "88px", //调整距离盒子高处的位置
                            style: {
                                fill: "#1cffff", //控制字体颜色
                                text: this.allNum, //控制第一行字显示内容
                                fontSize: 20,
                            },
                        },
                        {
                            type: "text",
                            left: "center",
                            top: "115px",
                            z: 10,
                            style: {
                                text: "总人数(人次)",
                                fontSize: 14,
                                fill: "#FFFFFF",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "人次",
                            type: "pie",
                            radius: ["60%", "70%"],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: "center",
                            },
                            labelLine: {
                                show: false,
                            },
                            data: this.xData3,
                        },
                    ],
                };
            },
            // 置空折线图
            resetZxt() {
                // 全部的折线图
                this.nineXData = []
                this.nineXTitle = []
                this.nineYData1 = []
                this.nineYData2 = []
                this.nineYData3 = []
                this.nineYData4 = []
                this.nineYData5 = []
                this.nineYData6 = []
                this.nineYData7 = []
                this.nineYData8 = []
                this.nineYData9 = []
            },
            // 入园方式颜色
            show3(index3, width) {
                let color = "";
                switch (index3) {
                    case 0:
                        color = "#FB7E48";
                        break;
                    case 1:
                        color = "#FFBB00";
                        break;
                    case 2:
                        color = "#37ECFF";
                        break;
                    case 3:
                        color = "#3DC95F";
                        break;
                    case 4:
                        color = "#9231FF";
                        break;
                    case 5:
                        color = "#036CF3";
                        break;
                }
                return `border-left: ${width} solid ${color};`;
            }
            ,


        },
    }
    ;
</script>
<style lang="scss" scoped>
    .tourist {
        .tabs {
            display: flex;
            /*align-items: center;*/
            /*justify-content: center;*/
            margin-bottom: 22px;

            > div {
                width: 176px;
                background: url("../assets/tourist/3-nav.-normal@2x.png") no-repeat;
                background-size: 100% 100%;
                color: #9bb0cc;
                font-size: 18px;
                text-align: center;
                padding: 10px 0;
                margin-right: 34px;
                cursor: pointer;
            }

            > div:last-child {
                margin-right: 0;
            }

            .active {
                position: relative;
                background: url("../assets/tourist/3-nav.-choose@2x.png") no-repeat;
                background-size: 100% 100%;
                color: #ffbb00;
            }

            .active::before,
            .active::after {
                content: "";
                position: absolute;
                left: 37px;
                top: 13px;
                width: 7px;
                height: 11px;
                background: url("../assets/img/1-nav-choose-arrow-left@2x.png") no-repeat;
                background-size: 100% 100%;
            }

            .active::after {
                left: 131px;
                background: url("../assets/img/1-nav-choose-arrow-left@2x.png") no-repeat;
                background-size: 100% 100%;
                transform: rotate(180deg);
            }
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 812px;

            .left {
                width: 1266px;
                height: 100%;
                padding: 25px;
                background: url("../assets/tourist/3-bg1@2x.png") no-repeat;
                background-size: 100% 100%;

                .left_top {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    img {
                        width: 488px;
                        height: 48px;
                    }

                    .all-select-box {
                        width: 360px;


                        .left_date {
                            width: 230px;
                        }
                    }
                }

                .allnum_echart {
                    max-width: 1200px;
                    min-height: 410px;
                    margin: 20px 0 30px;
                }

                .left_bottom {
                    display: flex;
                    justify-content: space-between;

                    .left_bottom_box {
                        .left_bottom_top {
                            display: flex;
                            align-items: flex-end;
                            justify-content: space-between;
                            margin-bottom: 23px;
                            width: 575px;

                            img {
                                width: 388px;
                                height: 48px;
                            }

                            .date_box {
                                width: 130px;
                            }

                            /*.date_box2 {*/
                            /*    width: 0px;*/
                            /*}*/
                        }

                        .tourist_type {
                            display: flex;
                            justify-content: space-between;
                            width: 572px;
                            min-height: 190px;
                            padding: 0 10px;

                            .pie {
                                position: absolute;
                                display: flex;
                                align-items: flex-start;
                                height: 49px;
                                transform-origin: 50% 100%;

                                .inner {
                                    width: 14px;
                                    height: 13px;
                                }
                            }

                            .typebg0,
                            .typebg1,
                            .typebg2,
                            .typebg3 {
                                display: flex;
                                justify-content: center;
                                width: 120px;
                                height: 120px;
                                padding-top: 11.5px;
                                background: url("../assets/tourist/3-title-visitortype-icon1@2x.png") no-repeat;
                                background-size: 100% 100%;
                                text-align: center;
                                font-size: 24px;
                                line-height: 100px;
                                color: #00fffd;
                            }

                            .typebg1 {
                                background: url("../assets/tourist/3-title-visitortype-icon2@2x.png") no-repeat;
                                background-size: 100% 100%;
                                color: #00f4ab;
                            }

                            .typebg2 {
                                background: url("../assets/tourist/3-title-visitortype-icon3@2x.png") no-repeat;
                                background-size: 100% 100%;
                                color: #f8af00;
                            }

                            .typebg3 {
                                background: url("../assets/tourist/3-title-visitortype-icon4@2x.png") no-repeat;
                                background-size: 100% 100%;
                                color: #ff8100;
                            }

                            .type_name {
                                width: 120px;
                                height: 31px;
                                margin: 10px 0 26px;
                                background: linear-gradient(270deg, rgba(11, 20, 35, 0), rgba(16, 93, 145, 0.6) 49%, rgba(13, 23, 40, 0));
                                color: #1cffff;
                                line-height: 31px;
                                font-size: 18px;
                                text-align: center;
                            }
                        }

                        .type_bootom {
                            display: flex;
                            width: 572px;
                            height: 168px;
                            padding: 24px;
                            background: url("../assets/tourist/3-bg1-2@2x.png") no-repeat;
                            background-size: 100% 100%;
                            font-size: 16px;

                            .type_bootom_box {
                                width: 100%;

                                .type_bootom_tit {
                                    width: 140px;
                                    height: 28px;
                                    line-height: 28px;
                                    padding-left: 24px;
                                    background: url("../assets/tourist/3-title-visitortype-icon6@2x.png") no-repeat;
                                    background-size: 100% 100%;
                                    margin-bottom: 14px;
                                    color: #ffffff;
                                    /*color: #9bb0cc;*/
                                }

                                .type_line {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 22px;

                                    .type_line_img {
                                        display: flex;
                                        align-items: center;
                                        width: 290px;
                                        height: 5px;
                                        background: #063357;
                                        margin: 0 11px;

                                        .line_img_box {
                                            position: relative;
                                            display: flex;
                                            align-items: center;
                                            // width: 70%;
                                            height: 100%;
                                        }

                                        .line_img {
                                            width: 100%;
                                            height: 100%;
                                        }

                                        .line_point {
                                            position: absolute;
                                            right: -10px;
                                            width: 19px;
                                            height: 19px;
                                        }
                                    }

                                    .type_line1 {
                                        width: 33px;
                                        text-align: right;
                                    }

                                    .type_line3 {
                                        color: #1cffff;
                                    }
                                }
                            }

                            .type_bootom_box2 {
                                padding-left: 40px;
                            }
                        }

                        .time_echart {
                            min-width: 380px;
                        }
                    }
                }
            }

            .right {
                background: url("../assets/tourist/3-bg2@2x.png") no-repeat;
                background-size: 100% 100%;
                width: 555px;
                height: 100%;
                padding: 25px 30px;

                .right_top {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-bottom: 9px;

                    img {
                        width: 184px;
                        height: 48px;
                    }

                    /*.date_box {*/
                    /*    width: 110px;*/
                    /*}*/
                }

                .right_echart_box {
                    display: flex;
                    margin-bottom: 13px;

                    .echart_l {
                        width: 224px;
                        height: 224px;
                        background: url("../assets/img/1-title-sales-icon@2x.png") no-repeat;
                        background-size: 100% 100%;
                    }

                    .echart_r {
                        display: flex;
                        flex-wrap: wrap;
                        padding-top: 10px;
                        margin-left: 30px;

                        .echart_r_box {
                            width: 50%;

                            .echart_r_box1,
                            .echart_r_box2 {
                                height: 28px;
                                line-height: 28px;
                                padding-left: 18px;
                                font-size: 16px;
                                color: #9bb0cc;
                            }

                            .echart_r_box2 {
                                margin-left: 2px;
                                font-size: 20px;
                                font-weight: 700;
                                color: #fff;
                                text-shadow: 0px 0px 4px 0px rgba(55, 236, 255, 0.6);
                            }
                        }
                    }
                }
                .all-ly-box{
                    margin-bottom: 12px;
                    .date_box{
                        width: 220px;
                    }
                    .right_tit {
                        width: 184px;
                        height: 48px;
                        /*margin-bottom: 12px;*/
                    }
                }
                .origin_top {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url("../assets/tourist/3-title-from-icon1@2x.png") no-repeat;
                    background-size: 100% 100%;
                    width: 472px;
                    height: 48px;
                    margin-bottom: 12px;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 16px;
                        color: #9bb0cc;
                    }

                    .num,
                    .num2 {
                        color: #fff;
                    }

                    .num {
                        font-size: 24px;
                        text-shadow: 0px 0px 4px 0px rgba(55, 236, 255, 0.6);
                    }

                    .num2 {
                        padding-top: 3px;
                    }
                }

                .ly-title {
                    width: 472px;
                    height: 48px;
                    margin-bottom: 12px;
                    background: url("../assets/tourist/3-title-from-icon1@2x.png") no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                    }

                    opacity: 0.7;
                    font-size: 16px;
                    font-weight: normal;
                    text-align: center;
                    color: #ffffff;
                }

                .origin {
                    width: 100%;
                    height: 320px;
                    overflow-y: scroll;
                    padding-right: 5px;
                    color: #b1b6bd;
                    font-size: 16px;

                    .origin_box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 40px;
                        margin-bottom: 8px;
                        padding: 0 25px;
                        background: rgba(16, 31, 62, 0.6);
                        box-shadow: 0px 0px 3px 0px rgba(146, 236, 255, 0.36) inset;

                        .origin_box_l {
                            position: relative;
                            padding-left: 15px;
                        }

                        .origin_box_l::before {
                            content: "";
                            position: absolute;
                            top: 5px;
                            left: 0px;
                            width: 6px;
                            height: 6px;
                            background: #fb7e48;
                            border-radius: 50%;
                        }

                        .origin_box_r1 {
                            color: #1cffff;
                        }
                    }

                    .empty {
                        width: 100%;
                    }
                }
            }
        }


        .dv-loading {
            padding-top: 20px;
            // max-height: 200px;
        }
    }

    .kj-box {
        background: url('../assets/kuaijie-box.png') no-repeat;
        background-size: 100% 100%;
        width: 96px;
        height: 33px;
        margin-left: 12px;
        cursor: pointer;

        p {
            text-align: center;
            line-height: 33px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
        }
    }

    .month-box {
        margin-top: 12px;
    }

    .flex {
        display: flex;
    }

    .ac {
        align-items: center;
    }

    .jb {
        justify-content: space-between;
    }

    .fw {
        flex-wrap: wrap;
    }

    .bbox {
        box-sizing: border-box;
    }

    .cr {
        cursor: pointer;
    }
</style>
<style lang="scss">
    .all-date-box
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .all-date-box
    .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .all-date-box
    .el-range-separator {
        color: #37ecff !important;
    }

    .chooseYearBox
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
    }

    .chooseYearBox
    .el-input__icon {
        line-height: 28px !important;
    }

    .chooseYearBox
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .is-selected {
        color: #1989FA;
    }

    .calendar-info {
        font-size: 12px;
    }

    .el-checkbox {
        margin: 0 5px 5px 0;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
    }

</style>
